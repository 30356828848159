import React from "react"
import Page from "../components/Pages/Page"
import colors from "../colors"

const Oferta = () => {
  const title = "Oferta Relâmpago | Flynow"
  const description =
    "Descubra Como Criar Bons Hábitos e Fazer Suas Metas Deixarem de Ser Sonhos no Papel em 2025"
  const id = "combo_ano_novo"
  return (
    <>
      <div
        style={{
          backgroundColor: "rgb(141, 35, 225)",
          padding: 50,
          color: "white",
          textAlign: "center",
        }}
      >
        <h3 style={{
          lineHeight:1.4
          }}>
          Descubra Como Criar Bons Hábitos e Fazer Suas Metas Deixarem de Ser
          Sonhos no Papel em 2025.
        </h3>
      </div>
      <Page hideHeader hideFooter title={title} description={description} pageId={id}>
        <div
          style={{
            padding: "2% 2%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p>
            <h4>Você prometeu que esse ano seria diferente.</h4>
            <br />
            Que finalmente colocaria em prática seus planos, criaria hábitos
            melhores e seria mais produtivo. <br />
            <br />
            Mas os dias passaram, a rotina tomou conta e, no final de 2024…{" "}
            <b>tudo parece igual</b>. <br />
            <br />
            Você está mais ou menos no mesmo lugar onde começou este ano.
            <br />
            <br />A pergunta que fica é: você quer{" "}
            <b>repetir os mesmos padrões de 2024 em 2025</b> ou quer finalmente{" "}
            <b>dar o próximo passo?</b> 🤔
            <br />
            <br />
            Imagine começar 2025…
            <br />
            <br />✅ Criando <u>bons hábitos e rotinas</u> que te ajudem a{" "}
            <b>ser mais constante e construir sua melhor versão</b>.<br />✅
            Sabendo <u>se planejar e definir metas eficientes</u> para{" "}
            <b>conquistar seus sonhos</b> que antes pareciam inalcançáveis.
            <br />✅ <u>Sendo mais produtivo</u> para se <b>sentir realizado</b>{" "}
            e ainda <b>ter mais tempo livre</b> para fazer o que quiser.
            <br />
            <br />
            <b>
              Sem mais promessas vazias. Sem intenções que só ficam no papel.
              Sem mais frustração
            </b>
            .🔥
            <br />
            <br />
            Hoje, você tem a chance de mudar isso. <br />
            <br />⌛ Somente até{" "}
            <i>
              <u>meia-noite</u>
            </i>
            , estamos oferecendo <b>50% OFF</b> no nosso combo de cursos:
            <br />
            <br />
            <li>
              <b>Curso de Hábitos</b>: aprenda a criar e manter bons hábitos de
              forma fácil para transformar sua vida (mesmo com pouco tempo).
            </li>
            <li>
              <b>Curso de Produtividade</b>: descubra como ser mais produtivo e
              recuperar o controle do seu tempo (e ter muito mais tempo livre).
              <br />
            </li>
            Clique abaixo para começar a viver seu melhor ano, a partir de
            agora: <br />
            <br />
            <a
              style={{
                display: "block",
                backgroundColor: colors.primary,
                padding: 25,
                color: "white",
                borderRadius: 12,
                // width: 400,
                textAlign: "center",
                alignSelf: "center",
                fontWeight:"bold",
                boxShadow: '0 0 10px #bbb'
              }}
              href={
                "https://buy.stripe.com/3cs3dZ4i59Gdg7KaF6?locale=pt-BR&prefilled_promo_code=NEWYEAR50"
              }
            >
              Sim! Eu Quero Viver Meu Melhor Ano 🔥
            </a>
          </p>
        </div>
      </Page>
    </>
  )
}

export default Oferta
