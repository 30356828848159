import React from "react"
import {parseToNumber, Products } from "../utils/product"
import ComboTemplate from "../components/Templates/ComboTemplate"
import { useParams } from "../hooks"

const ComboFlynow = () => {
  const {params} =useParams()

  const CUPOM = params.cupom || 'COMBO30'

  const data = {
    id: "combo_cursos",
    title: "[CURSOS] DOMINE A ARTE DA PRODUTIVIDADE + DOMINE SEUS HÁBITOS",
    discontPercentage: parseToNumber(CUPOM) || 50,
    URL_CHECKOUT: "https://buy.stripe.com/3cs3dZ4i59Gdg7KaF6?locale=pt-BR&prefilled_promo_code="+CUPOM,
    cta: "ADQUIRIR COMBO PROMOCIONAL",
    sections: [
      {
        section: "CURSOS",
        data: Products.cursos
      },
    ],
  }
  return <ComboTemplate  {...data} />
}
export default ComboFlynow
